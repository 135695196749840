import { Component } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  ActivatedRoute,
  Router,
} from '@angular/router';

import {
  AppService,
  UserService,
  ValidationService,
} from '../../core';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['../register/register.component.scss'],
  standalone: false,
})
export class ResetPasswordComponent {
  isSubmitting = false;
  form: FormGroup;
  hidePassword = true;
  hideRepeatPassword = true;

  constructor(
    private appService: AppService,
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private userService: UserService,
    private validationService: ValidationService,
  ) {
    this.appService.title = 'pages.resetPassword';

    this.form = this.fb.group({
      token: [this.route.snapshot.paramMap.get('hash'), [Validators.required]],
      password: ['', [Validators.required, Validators.minLength(6), this.validationService.matchValidator('password_confirmation', null, true)]],
      password_confirmation: ['', [Validators.required, this.validationService.matchValidator('password')]],
    });
  }

  submitForm() {
    if (!this.form.valid) {
      return;
    }
    this.isSubmitting = true;

    const values = Object.assign({}, this.form.getRawValue());
    this.userService.renewPassword(values).subscribe({
      next: () => {
        this.isSubmitting = false;
        this.router.navigateByUrl('/login');
      },
      error: err => {
        this.isSubmitting = false;
        this.router.navigateByUrl(err.redirect ? err.redirect : '/login');
      },
    });
  }
}
