import {
  Component,
  OnInit,
} from '@angular/core';
import {
  ActivatedRoute,
  Router,
} from '@angular/router';

import { UserService } from '../../core';

@Component({
  selector: 'app-saml-login',
  templateUrl: './saml-login.component.html',
  styleUrls: ['./saml-login-component.scss'],
  standalone: false,
})
export class SamlLoginComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    const token = this.route.snapshot.paramMap.get('token');
    if (token) {
      this.userService.samlCallback({ token }).subscribe({
        next: () => {
          this.userService.profile().subscribe({
            next: () => {
              this.userService.openHomePage();
            },
            error: () => {
              this.router.navigateByUrl('/login');
            },
          });
        },
        error: () => {
          this.router.navigateByUrl('/login');
        },
      });
    } else {
      this.router.navigateByUrl('/login');
    }
  }
}
