import { CommonModule } from '@angular/common';
import {
  ModuleWithProviders,
  NgModule,
} from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';

import { AuthRoutingModule } from './auth-routing.module';

import { SharedModule } from '../shared/shared.module';

import {
  ConfirmAccountComponent,
  ForgotPasswordComponent,
  LoginComponent,
  RegisterComponent,
  ResetPasswordComponent,
  SamlLoginComponent,
  SocialLoginComponent,
} from './';

const APP_COMPONENTS = [
  RegisterComponent,
  ConfirmAccountComponent,
  LoginComponent,
  ForgotPasswordComponent,
  ResetPasswordComponent,
  SocialLoginComponent,
  SamlLoginComponent,
];

@NgModule({
  imports: [
    CommonModule,
    AuthRoutingModule,
    ReactiveFormsModule,
    SharedModule,
    TranslateModule,
  ],
  declarations: [
    ...APP_COMPONENTS,
  ],
  exports: [
    ...APP_COMPONENTS,
  ],
})
export class AuthModule {
  static forRoot(): ModuleWithProviders<AuthModule> {
    return {
      ngModule: AuthModule,
    };
  }
}
