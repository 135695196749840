import {
  Component,
  OnInit,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Title } from '@angular/platform-browser';
import {
  ActivatedRoute,
  Router,
} from '@angular/router';

import { environment } from '../../../environment';
import {
  AppService,
  UserService,
  ValidationService,
} from '../../core';

@Component({
  selector: 'app-confirm-account',
  templateUrl: './confirm.component.html',
  styleUrls: ['confirm.component.scss', '../register/register.component.scss'],
  standalone: false,
})
export class ConfirmAccountComponent implements OnInit {
  loading = true;
  withPassword = false;
  isSubmitting = false;
  form: FormGroup;
  hidePassword = true;
  hideRepeatPassword = true;

  constructor(
    private title: Title,
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private appService: AppService,
    private fb: FormBuilder,
    private validationService: ValidationService,
  ) {
    this.withPassword = this.route.snapshot.data['password'];
    this.title.setTitle(`Confirm Account | ${environment.project}`);
    this.form = this.fb.group({
      token: [this.route.snapshot.paramMap.get('hash'), [Validators.required]],
      password: ['', [Validators.required, Validators.minLength(6), this.validationService.matchValidator('password_confirmation', null, true)]],
      password_confirmation: ['', [Validators.required, this.validationService.matchValidator('password')]],
    });
  }

  ngOnInit() {
    if (!this.withPassword) {
      this.confirm();
    }
  }

  confirm(): void {
    let data;
    if (this.withPassword){
      if (!this.form.valid) {
        return;
      }
      this.isSubmitting = true;

      data = Object.assign({}, this.form.getRawValue());
    } else {
      data = {
        token: this.route.snapshot.paramMap.get('hash'),
      };
    }
    this.userService.confirmEmail(data, this.withPassword)
      .subscribe({
        next: () => {
          this.loading = false;
          this.router.navigateByUrl('/login');
        },
        error: err => {
          this.loading = false;
          this.router.navigateByUrl(err.redirect ? err.redirect : '/login');
        },
      });
  }
}
