import { Component } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

import {
  AppService,
  UserService,
} from '../../core';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['../register/register.component.scss'],
  standalone: false,
})
export class ForgotPasswordComponent {
  form: FormGroup;
  isSubmitting = false;

  constructor(
    private appService: AppService,
    private title: Title,
    private router: Router,
    private userService: UserService,
    private fb: FormBuilder,
  ) {
    this.appService.title = 'pages.forgotPassword';

    this.form = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  submitForm() {
    this.isSubmitting = true;

    const formData = this.form.value;
    formData.email = formData.email.replace(/\s/, '');
    this.userService
      .forgotPassword(formData)
      .subscribe({
        next:() => {
          this.router.navigateByUrl('/login');
        },
        error: () => {
          this.isSubmitting = false;
        },
      });
  }
}
