import { HttpParams } from '@angular/common/http';
import { Component } from '@angular/core';
import {
  ActivatedRoute,
  Params,
  Router,
} from '@angular/router';

import { first } from 'rxjs';

import {
  SocialProvider,
  SocialService,
  Token,
  UserService,
} from '../../core';

@Component({
  selector: 'app-social-login',
  template: ``,
  standalone: false,
})
export class SocialLoginComponent {
  private readonly provider: SocialProvider;

  constructor(
    private socialService: SocialService,
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
  ) {
    this.provider = this.route.snapshot.paramMap.get('provider') as SocialProvider;
    this.route.queryParams.pipe(first()).subscribe(params => {
      this.userService.populatePromise.then(user => {
        if (!user?.id) {
          this.socialLogin(params);
        } else {
          this.socialConnect(params);
        }
      });
    });
  }

  socialLogin(params: Params) {
    const httpParams = (new HttpParams()).appendAll(params);

    this.socialService.handler(this.provider, httpParams).subscribe({
      next: (token: Token) => {
        this.userService.setAuth(token);
        this.userService.profile().subscribe({
          next: () => {
            this.userService.openHomePage();
          },
        });
      },
      error: err => {
        this.router.navigateByUrl('/login');
      },
    });
  }

  socialConnect(params: Params) {
    const httpParams = (new HttpParams()).appendAll(params);

    this.socialService.handler(this.provider, httpParams).subscribe({
      next: (token: Token) => {
        this.userService.profile().subscribe({
          next: () => {
            this.router.navigateByUrl(this.socialService.getReturnUrl());
          },
        });
      },
      error: err => {
        this.router.navigateByUrl(this.socialService.getReturnUrl());
      },
    });
  }
}
